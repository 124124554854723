import { ChangeLogDto, PagingOptions } from "@libs/api/dtos/index.ts";
import { RefDataDto } from "@libs/api/ref-data/dto.ts";
import { InvoiceStatus } from "@libs/gateways/billing/BillingGateway.dtos.ts";

export interface UserSettingDto {
  userId: string;
  eTag: string;
  patientLabel?: PatientDisplayCode;
  clinicalView?: ClinicalViewDto;
  clinicalTaskSettings?: ClinicalTaskSettingDto;
  changeLog?: ChangeLogDto;
  showOnCalendar?: boolean;
}

export interface PatientSettingDto {
  patientId: string;
  eTag: string;
  pronounMessage: boolean;
  changeLog?: ChangeLogDto;
}

export type UpdatePatientSettingDto = Omit<PatientSettingDto, "eTag"> & {
  eTag?: string;
};

export type UpdateUserSettingDto = Omit<UserSettingDto, "eTag"> & {
  eTag?: string;
};

export enum UserStorageKeys {
  OpenPatientTabs = "openPatientTabs",
  OpenDocuments = "openDocuments",
  OpenTemplates = "openTemplates",
  SelectedProviders = "selectedProviders",
  OrgUnitIds = "orgUnitIds",
  CalendarView = "calendarView",
  FavouriteTemplates = "favouriteTemplates",
  TemplateFilterTabs = "templateFilterTabs",
  QuickLinksOnBottom = "quickLinksOnBottom",
  DraftItemsFilters = "draftItemsFilter",
  ReverseObservationMatrixDates = "reverseObservationMatrixDates"
}

export interface PatchUserSettingDto
  extends Patch<Omit<UserSettingDto, "changeLog">> {
  userId: string;
}

export interface PatchPatientSettingDto
  extends Patch<Omit<PatientSettingDto, "changeLog">> {
  patientId: string;
  eTag?: string;
}

type UserStorageValue = object | string | number | boolean;
export interface UserStorageDto {
  id: string;
  jsonData: UserStorageValue;
  userId: string;
  key: string;
  eTag: string;
  changeLog?: ChangeLogDto;
}
export interface AddUserStorageDto
  extends Omit<UserStorageDto, "id" | "eTag" | "changeLog"> {}

export interface ClinicalViewDto {
  defaultDockView?: string;
  patientSummaryPage?: PatientSummaryPageDto[];
  selectedTreeView?: string;
  onlyShowMyNotes?: boolean;
  pastVisitsReasonAtBottom?: boolean;
  clinicalNotesFormat?: string;
  clinicalWorkflow?: string;
  enforceDiscardConsultReason?: boolean;
  timerEnabled?: boolean;
}

export interface PatientSummaryPageDto {
  key: string;
  checked?: boolean;
  position: number;
}

interface ClinicalTaskSettingDto {
  value: ClinicalTaskSettingItemDto[];
}

interface SecuritySettingDto {
  inactivityTimeoutEnabled: boolean;
  inactivityTimeoutThresholdMinutes: number;
}

interface ClinicalTaskSettingItemDto {
  id: string;
  visitNumber: string;
  isDefault: boolean;
}

interface CommsDefaultCampaignDto {
  value: CommsDefaultCampaignSettingDto[];
}

export interface CommsDefaultCampaignSettingDto {
  templateId: string;
  commType: string;
}

export interface RefPatientSummaryDto extends RefDataDto<string> {
  isChecked: boolean;
  permission?: string;
}

export interface RefTreeViewDto extends RefDataDto<string> {
  permission?: string;
}

export interface RefBusinessRoleAbbreviationDto extends RefDataDto<string> {
  businessRoleCode: string;
}

export enum PatientDisplayCode {
  Patient = "PAT",
  Client = "CLI"
}

export interface TenantSettingDto {
  eTag: string;
  patientLabel?: PatientDisplayCode;
  clinicalTaskSettings?: ClinicalTaskSettingDto;
  commsDefaultCampaign?: CommsDefaultCampaignDto;
  securitySettings?: SecuritySettingDto;
  changeLog?: ChangeLogDto;
}

export interface CalendarEventExtensionDto {
  calendarEventExtensionId: string;
  calendarEventId: string;
  invoiceId?: string;
  providerComment?: string;
  noChargeComment?: string;
  hasEncounter?: boolean;
  openEncounters?: CalendarEventOpenEncounterDto[];
  eTag: string;
  changeLog?: ChangeLogDto;
  calendarEventAttendeeExtensions?: CalendarEventAttendeeExtensionDto[];
}

interface CalendarEventOpenEncounterDto {
  encounterId: string;
  userId: string;
  patientId: string;
}

export interface EncounterExtensionDto {
  encounterId: string;
  claimId?: string;
  claimNumber?: string;
  calendarEventId?: string;
}

export interface TemplateExtensionDto {
  templateId: string;
  eTag: string;
  isComplex?: boolean;
}

export interface OrgUnitSettingDto {
  orgUnitId: string;
  waitingRoom?: WaitingRoomSettingDto;
  preferenceSetting?: PreferenceSettingDto;
  appointmentSetting?: AppointmentSettingDto;
  eTag: string;
  changeLog?: ChangeLogDto;
}

export interface WaitingRoomSettingDto {
  withProvider?: boolean;
  finalised?: boolean;
}

export interface PreferenceSettingDto {
  captureApptCancellationReason: boolean;
  autoLinkCondition: boolean;
}

export interface AppointmentSettingDto {
  apptTypeBaseInterval: string;
}

export interface CampaignRenderArgsDto {
  channelCode: string;
  calendarEventId: string;
  contactId?: string;
}

export interface UserAccountVerifyPinRequestDto {
  userSignInName: string;
  pin: string;
}

export interface CalendarEventAttendeeExtensionDto {
  attendeeId: string;
  invoiceId?: string;
  invoiceStatus?: InvoiceStatus;
}

export enum EventType {
  appointment = "Appointment",
  consult = "CON",
  invoice = "Invoice",
  patient = "Patient",
  recordUpdate = "RU"
}

export enum EventVerb {
  encounterClosed = "EncounterClosed",
  encounterStarted = "EncounterStarted",
  create = "Create"
}

export interface RecentPatientDto {
  typeId: string;
  patientId: string;
  eventTime: string;
  eventType?: EventType;
  eventVerb?: EventVerb;
  recentPatientsId: string;
}

export interface GetRecentPatientsDto extends PagingOptions {
  userId: string;
  patientIds?: string[];
  eventType?: EventType;
  eventVerb?: EventVerb;
  eventTimeFrom?: string;
  eventTimeTo?: string;
}

export interface UserHotkeys {
  [key: string]: string;
}

export interface QuickAccessDto {
  quickAccessId: string;
  quickAccessSettings: QuickAccessSettingDto[];
  eTag?: string;
}

export interface QuickAccessOptionsDto {
  quickAccessSettings: QuickAccessSettingDto[];
  eTag?: string;
}

export interface QuickAccessSettingDto {
  code: string;
  icon: string;
  title: string;
  isSystem?: boolean;
  isHidden?: boolean;
  documentId?: string;
  isSourceTemplateDeleted?: boolean;
}

export type UpdateQuickAccessDto = Omit<QuickAccessOptionsDto, "eTag"> & {
  eTag?: string;
};

export enum QuickAccessType {
  PrescribingRx = "PRES",
  ImagingRequest = "IMGREQ",
  Add = "Add",
  Document = "DOC",
  More = "More",
  OnlineForm = "OLFORM"
}
